<template>
  <div>
    <ManageCourse />
  </div>
</template>
<script>
import ManageCourse from "@/components/Course/ManageCourse";
export default {
  components: {
    ManageCourse,
  },
  created() {},
};
</script>
